<template>
    <div class="main_wrapper">
        <div class="modal_wrapper" v-if="$parent.modal">
            <div class="modal_window">
                <section class="content_pannel edit_pannel">
                    <header class="pannel_header">
                        <h3>Usuarios<span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg></span>Editar</h3>
                        <div class="close_pannel" @click="close()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg>
                        </div>
                    </header>
                    <div class="content_inner">
                        <div class="grid grid_pad grid_wrap">
                            <div class="grid_col grid_col_1_2 form_block">
                                <label>Email</label>
                                <div class="input_block">
                                    <input type="text" v-model="user.email">
                                </div> 
                            </div>
                            <div class="grid_col grid_col_1_2 form_block">
                                <label>Creado</label>
                                <div class="input_block">
                                    <input type="datetime-local" v-model="user.created_at" disabled>
                                </div> 
                            </div>
                            <div class="grid_col grid_col_1_2 form_block">
                                <label>Primer Login</label>
                                <div class="input_block">
                                    <input type="datetime-local" v-model="user.first_login" disabled>
                                </div> 
                            </div>
                            <div class="grid_col grid_col_1_2 form_block">
                                <label>Ultimo Login</label>
                                <div class="input_block">
                                    <input type="datetime-local" v-model="user.updated_at" disabled>
                                </div> 
                            </div>
                            <div class="grid_col form_block wp100">
                                <label>Premiums ({{user.premiums.length}})</label>
                                <div class="grid_col grid_col_1_4 form_block" @click="nuevoPremium()">
                                    <button class="btn med grey ko graphic graphic_right center wide">Nuevo<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg></button>
                                </div>
                                <div v-if="this.premiumOp">
                                    <select id="inpPremTipo" class="inpPrem" @change="onChangeSel" name="tipo">
                                        <option value="3" selected>Mensual</option>
                                        <option value="4">Anual</option>
                                    </select>
                                    <input class="inpPrem" id="inpPremDate" type="datetime-local" name="hasta">
                                    <div class="inpPrem inline" @click="savePremium()">
                                        <button class="btn med grey graphic graphic_right center wide">Guardar<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13 3h2.996v5h-2.996v-5zm11 1v20h-24v-24h20l4 4zm-17 5h10v-7h-10v7zm15-4.171l-2.828-2.829h-.172v9h-14v-9h-3v20h20v-17.171z"/></svg></button>
                                    </div>
                                </div>
                                <div class="table_component">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Tipo</th>
                                                <th>Imagen</th>
                                                <th>Creado</th>
                                                <th>Actualizado</th>
                                                <th>Caduca</th>
                                                <th>Activa</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(premium, index) in user.premiums" :key="index">
                                                <td>{{getTipo(premium.type)}}</td>
                                                <td><a v-if="premium.img" :href="`${url_imgdb}premiums/${premium.img}`" target="_blank">Ver</a></td>
                                                <td><input type="datetime-local" v-model="premium.created_at" disabled></td>
                                                <td><input type="datetime-local" v-model="premium.updated_at" disabled></td>
                                                <td><input type="datetime-local" v-model="premium.deleted_at"></td>
                                                <td v-if="new Date(premium.deleted_at) > new Date()"><svg style="width: 20px; height: 20px;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"/></svg></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="grid_col grid_col_1_1 separator bordered">
                                <span></span>
                            </div>
                            <div class="grid_col grid_col_1_2">
                                <div class="grid grid_pad">
                                    <div class="grid_col grid_col_1_2 form_block" @click="close()">
                                        <button class="btn med grey ko graphic graphic_right center wide">Cancelar<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z"/></svg></button>
                                    </div>
                                    <div class="grid_col grid_col_1_2 form_block" @click="save()">
                                        <button class="btn med grey graphic graphic_right center wide">Guardar cambios<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13 3h2.996v5h-2.996v-5zm11 1v20h-24v-24h20l4 4zm-17 5h10v-7h-10v7zm15-4.171l-2.828-2.829h-.172v9h-14v-9h-3v20h20v-17.171z"/></svg></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <main class="content_wrapper">
            <div class="inner">
                <section class="page_header">
                    <h2>Usuarios</h2>
                    <div class="page_header_nav">
                        <div class="btn med grey graphic_right" @click="nuevo()">
                            <span>Agregar nuevo</span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>
                        </div>
                    </div>
                </section>
                <section class="content_pannel">
                    <header class="pannel_header">
                        <h3>Listado de usuarios</h3>
                    </header>
                    <div class="content_inner">
                        <div class="grid grid_pad grid_wrap">
                            <div class="grid_col grid_col_1_1 form_block">
                                <VityTable 
                                    v-if="url"
                                    :columns="columns"
                                    :url="url+'users'"
                                    :params="{
                                        admin:$store.state.user,
                                    }"
                                    tableFooter
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    </div>
</template>

<script>
import axios from 'axios'
import VityTable from '@/components/VityUtils/vitytables/VityTables.vue';

export default {
    name: 'Usuarios',
    components:{
        VityTable
    },
    data: function(){
        return{
            columns:[
                {
                    name: 'ID',
                    dbName: 'id',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Email',
                    dbName: 'email',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Ultimo Login',
                    dbName: 'token_update',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Accion',
                    center: true,
                    dbName: '',
                    orderable: false,
                    searchable: false,
                    template: '<a href="/usuarios/TPLData"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.127 22.564l-7.126 1.436 1.438-7.125 5.688 5.689zm-4.274-7.104l5.688 5.689 15.46-15.46-5.689-5.689-15.459 15.46z"/></svg></a>',
                    reference: 'id'
                },
            ],
            url: process.env.VUE_APP_URL,
            url_imgdb: process.env.VUE_APP_URL_IMGDB,
            user: null,
            premiumOp: null
        }
    },
    methods: {
        init: function(){
            if(this.$route.params.id)
                this.load(this.$route.params.id);
            else
                this.user = {}
        },
        load: function(id){
            axios
                .get(process.env.VUE_APP_URL+`users/${id}`, {
                    params: {
                        admin: this.$store.state.user,
                        premiums: 1
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.user = rs.data;
                        this.user.created_at = this.user.created_at.replace(' ', 'T');
                        this.user.created_at = this.user.created_at.replace(/\.[0]+Z/gm, '');
                        this.user.first_login = this.user.first_login.replace(' ', 'T');
                        this.user.first_login = this.user.first_login.replace(/\.[0]+Z/gm, '');
                        this.user.updated_at = this.user.updated_at.replace(' ', 'T');
                        this.user.updated_at = this.user.updated_at.replace(/\.[0]+Z/gm, '');

                        this.user.premiums.forEach(element => {
                            element.created_at = element.created_at.replace(' ', 'T');
                            element.created_at = element.created_at.replace(/\.[0]+Z/gm, '');
                            element.updated_at = element.updated_at.replace(' ', 'T');
                            element.updated_at = element.updated_at.replace(/\.[0]+Z/gm, '');
                            if(element.deleted_at){
                                element.deleted_at = element.deleted_at.replace(' ', 'T');
                                element.deleted_at = element.deleted_at.replace(/\.[0]+Z/gm, '');
                            }
                        });

                        this.$parent.modal = true;
                    }
                })
        },
        save: function(){
            if(!this.user.email){
                this.$parent.error = {type: 3, msg: 'Faltan campos por rellenar'};
                return;
            }

            axios
                .post(process.env.VUE_APP_URL+`users`+(this.user.id ? `/${this.user.id}` : ''), {
                    admin: this.$store.state.user,
                    email: this.user.email,
                    premiums: this.user.premiums
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        if(this.user.id){
                            this.$parent.error = {type: 1, msg: 'Guardado correctamente'};
                            this.init();
                        }
                        else
                            this.$router.push(`/usuarios/${rs.data}`);
                    }
                    else
                        this.$parent.error = {type: 2, msg: rs.msg};
                })
                .catch(error => {
                    this.$parent.error = {type: 2, msg: 'Fallo al conectar'};
                })
        },
        close: function(){
            this.$parent.modal = false;
            this.user = null;
            this.$router.push('/usuarios');
        },
        nuevo: function(){
            this.user = {premiums: []};
            this.$parent.modal = true;
            this.premiumOp = false;
        },
        getTipo: function(tipo){
            switch(tipo){
                case 1:
                    return "Hora Cortesia"
                case 2:
                    return "48 Horas imagen"
                case 3:
                    return "Mensual"
                case 4:
                    return "Anual"
                default:
                    return "No Definido"
            }
        },
        nuevoPremium: function(){
            this.premiumOp = true;
        },
        onChangeSel: function(e){
            var date = new Date();
            if(e.target.value == 3)
                date.setDate(date.getDate() + 30);
            else if(e.target.value == 4)
                date.setDate(date.getDate() + 365);

            document.getElementById("inpPremDate").value = date.toISOString().slice(0,19);
        },savePremium: function(){
             axios
                .post(process.env.VUE_APP_URL+`addPremium`, {
                    admin: this.$store.state.user,
                    user_id: this.user.id,
                    hasta: document.getElementById("inpPremDate").value.replace('T', ' '),
                    tipo: document.getElementById("inpPremTipo").value
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        if(this.user.id){
                            this.$parent.error = {type: 1, msg: 'Guardado correctamente'};
                            this.init();
                        }
                    }
                    else
                        this.$parent.error = {type: 2, msg: rs.msg};
                })
                .catch(error => {
                    this.$parent.error = {type: 2, msg: 'Fallo al conectar'};
                })

        }
    },
    created: function(){
        this.init();
    },
    watch: {
        $route: function(){
            this.init();
        }
    }
}
</script>